/* eslint-disable import/no-unresolved */
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
// import { Helmet } from 'react-helmet';
import 'lazysizes';
import 'typeface-montserrat';
import 'bootstrap/dist/css/bootstrap.css';
import '@fontsource/nunito';

import Menu from '../../../organisms/v2/Menu';
import BannerWhitePaper from '../../../organisms/V4/BannerWhitepaper';
import WhitePaperContent from '../../../organisms/V4/WhitePaper';
import Footer from '../../../organisms/Footer';
import withDefautlHoc from '../../../_hoc/global';
import Consultation from '../../../organisms/V4/Consultation';

import withIntl from '../../../../helper/hoc/withIntl';

const WhitePaperPage = ({ enPrefix }) => {
	const intl = useIntl();

	// const intl = useIntl();

	const navigateToFormSignUp = () => {
		let currentUrl = '';
		if (typeof window !== 'undefined') {
			currentUrl = window.location.href;
		}
		if (enPrefix) {
			navigate(`/en/form/signup?fromUrl=${currentUrl}`);
		} else {
			navigate(`/form/signup?lang=id&fromUrl=${currentUrl}`);
		}
	};
	return (
		<>
			<Menu enPrefix={enPrefix} onDemoClick={navigateToFormSignUp} />
			<BannerWhitePaper />
			<WhitePaperContent />
			<Consultation
				title={intl.formatMessage({ id: `whitepaper.banner.title` })}
				desc={intl.formatMessage({ id: `whitepaper.banner.sub` })}
				txtButton={intl.formatMessage({ id: `whitepaper.banner.button` })}
				onClick={navigateToFormSignUp}
				idBtn="CTA-wpp-bottom"
			/>
			<Footer enPrefix={enPrefix} />
		</>
	);
};

WhitePaperPage.propTypes = {
	enPrefix: PropTypes.string.isRequired,
};

export default withIntl(withDefautlHoc(WhitePaperPage));
