/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useIntl } from 'gatsby-plugin-intl';

import ModalWhitePaper from '../../WhitePaperContent/modal';

import ImageRender from '../../../../helper/image';
import H2 from '../../../atoms/Label/Headers/H2';
import Paragraph from '../../../atoms/Label/Paragraph/Clear';
import ButtonLink from '../../../atoms/Button/Link';
import { Wrapper } from './style';

const WhitePaperContent = () => {
	const intl = useIntl();
	const [isModalOpen, setModalOpen] = useState({
		isOpen: false,
		actionUrl:
			'https://ca01da1c.sibforms.com/serve/MUIEAKfoUwuC0CTB324HbbtlN1D6Koa3va34OUk-D8AUUeBsB35f2_LhDDCA1c3fTWTbXPYdsz1KiO4_a5WWDLBm6fsFtzOKptmad7Y5UU4119X5toATw5-TT_0OBDNtJYde7yQUm_9WDAacnhFlm5yPl2wQZWYMP5pfLquRZsg5QlwU7QjjYSOIc6LuIwfkEJZ8NHeIiklAzT2d',
	});
	return (
		<Wrapper>
			<div className="white-paper-page" id="mt-white-paper-page">
				<div className="container container-mobile">
					<div className="item-list-container">
						<div className="item-paper">
							<div className="item-paper__left">
								{ImageRender(
									'https://seiturju.sirv.com/Chatbiz/pdf/cover/cover-pengalaman.png?format=webp&w=300',
									'https://seiturju.sirv.com/Chatbiz/pdf/cover/cover-pengalaman.png?format=webp&w=300',
									'https://seiturju.sirv.com/Chatbiz/pdf/cover/cover-pengalaman.png?format=webp&w=300',
									'',
									'',
									'',
									'pengalaman',
								)}
							</div>
							<div className="item-paper__right">
								<H2 className="h2Default text-wrapper__title">
									{intl.formatMessage({ id: `whitepaper.first_list.title` })}
								</H2>
								<Paragraph className="pDefault text-wrapper__desc">
									{intl.formatMessage({ id: `whitepaper.first_list.sub` })}
								</Paragraph>
								<div className="bottom-wrapper">
									<Paragraph className="pDefault txtlang">
										<ReactSVG src="https://seiturju.sirv.com/Chatbiz/ico-lang.svg" />
										{intl.formatMessage({ id: `whitepaper.lang` })}
									</Paragraph>
									<ButtonLink
										className="btnLinkDefault mt-btn-downlaodbuku"
										onClick={() => {
											setModalOpen({
												isOpen: true,
												actionUrl:
													'https://ca01da1c.sibforms.com/serve/MUIEAKfoUwuC0CTB324HbbtlN1D6Koa3va34OUk-D8AUUeBsB35f2_LhDDCA1c3fTWTbXPYdsz1KiO4_a5WWDLBm6fsFtzOKptmad7Y5UU4119X5toATw5-TT_0OBDNtJYde7yQUm_9WDAacnhFlm5yPl2wQZWYMP5pfLquRZsg5QlwU7QjjYSOIc6LuIwfkEJZ8NHeIiklAzT2d',
											});
										}}
										id="mtBtnDownloadBuku1"
									>
										{intl.formatMessage({ id: `whitepaper.download` })}
									</ButtonLink>
								</div>
							</div>
						</div>
						<div className="item-paper">
							<div className="item-paper__left">
								{ImageRender(
									'https://seiturju.sirv.com/Chatbiz/pdf/cover/cover-leadgeneration.png?format=webp&w=300',
									'https://seiturju.sirv.com/Chatbiz/pdf/cover/cover-leadgeneration.png?format=webp&w=300',
									'https://seiturju.sirv.com/Chatbiz/pdf/cover/cover-leadgeneration.png?format=webp&w=300',
									'',
									'',
									'',
									'leadgeneration',
								)}
							</div>
							<div className="item-paper__right">
								<H2 className="h2Default text-wrapper__title">
									{intl.formatMessage({ id: `whitepaper.second_list.title` })}
								</H2>
								<Paragraph className="pDefault text-wrapper__desc">
									{intl.formatMessage({ id: `whitepaper.second_list.sub` })}
								</Paragraph>
								<div className="bottom-wrapper">
									<Paragraph className="pDefault txtlang">
										<ReactSVG src="https://seiturju.sirv.com/Chatbiz/ico-lang.svg" />
										{intl.formatMessage({ id: `whitepaper.lang` })}
									</Paragraph>
									<ButtonLink
										className="btnLinkDefault mt-btn-downlaodbuku"
										onClick={() => {
											setModalOpen({
												isOpen: true,
												actionUrl:
													'https://ca01da1c.sibforms.com/serve/MUIEAAGwRu3vF-5bSCal0K_lVcaKpIf5w09FfC_VOfGUJlDB7YPu9LFV-PefcT06HBmajg8_nJqMZfYk6nv6tgdwphT8x-EzWt6D1rmd1XYwiSOyDYTOSV_7oaUaG-5Enbu587BQz2orMybqdqaYJji4FBJ9lyuQg4G-5f8Y9UyyLSL74QhKuJFUDOLV8tv9nTzaLrDICMmZML2Y',
											});
										}}
										id="mtBtnDownloadBuku2"
									>
										{intl.formatMessage({ id: `whitepaper.download` })}
									</ButtonLink>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalWhitePaper
				modalIsOpen={isModalOpen.isOpen}
				onClose={() => {
					setModalOpen({
						...isModalOpen,
						isOpen: false,
					});
				}}
				acrtionUrl={isModalOpen.actionUrl}
			/>
		</Wrapper>
	);
};

export default WhitePaperContent;
