import styled from 'styled-components';
import { Mobile } from '../../../atoms/_Variable/var';

const MOBILE_BREAK_POINT = 900;

export const Wrapper = styled.div`
	.white-paper-page {
		background-color: #f9fbff;

		padding-bottom: 6.25rem;

		@media screen and (max-width: ${MOBILE_BREAK_POINT}px) {
			padding-top: 13.625rem;
			.container-mobile {
				padding-left: 20px;
				padding-right: 20px;
				margin-left: 0;
				max-width: 100%;
			}
		}
		.item-list-container {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			/* padding-right: 9%; */
			padding-left: 12%;
			@media screen and (max-width: ${MOBILE_BREAK_POINT}px) {
				padding-right: 0;
				padding-left: 0;
			}
			.item-paper {
				width: 100%;
				/* max-width: 860px; */

				display: flex;
				background: #ffffff;
				box-shadow: 0px 0px 50px rgba(126, 169, 210, 0.15);
				border-radius: 8px;
				position: relative;
				&:not(:first-child) {
					margin-top: 3.125rem;
					@media screen and (max-width: ${MOBILE_BREAK_POINT}px) {
						margin-top: 13.625rem;
					}
				}
				@media screen and (max-width: ${MOBILE_BREAK_POINT}px) {
					flex-direction: column;
				}
				&__left {
					position: absolute;
					left: -140px;
					top: 0;
					border-radius: 8px;
					img,
					picture {
						max-width: 259px;
						border-radius: 8px;
					}
					@media screen and (max-width: ${MOBILE_BREAK_POINT}px) {
						position: absolute;
						top: -180px;
						left: 1.063rem;
					}
				}
				&__right {
					padding: 2.5rem 3.75rem 4.125rem 11.063rem;
					@media screen and (max-width: ${MOBILE_BREAK_POINT}px) {
						padding: 11.938rem 1.063rem 2.438rem 1.063rem;
					}
					.bottom-wrapper {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-top: 3.5rem;

						@media screen and (max-width: ${Mobile}) {
							flex-direction: column;
						}
						.txtlang {
							color: #80888f;
							display: flex;
							@media screen and (max-width: ${Mobile}) {
								align-self: flex-start;
							}
							svg {
								width: 22px;
								height: 22px;
								margin-right: 0.75rem;
								path {
									fill: #80888f;
								}
							}
						}
						.btnLinkDefault {
							font-size: 0.813rem;
							margin-right: 2.063rem;
							font-weight: bold;
							padding: 0.625rem 2rem;
							@media screen and (max-width: ${Mobile}) {
								align-self: flex-end;
								margin-top: 2.563rem;
							}
						}
					}
				}
			}
		}
	}
`;

export default Wrapper;
