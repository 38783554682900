import styled from 'styled-components';
import { AverageSize, Tablet, ExtraLarge, Mobile, Large } from '../../../atoms/_Variable/var';

export const CUSTOM_TABLET = 950;

export const Wrapper = styled.section`
	margin-top: 40px;
	padding-top: 9rem;
	padding-bottom: 6.125rem;

	background-color: #f9fbff;

	@media only screen and (max-width: ${AverageSize}) {
		padding-top: 7.5rem;
	}

	@media screen and (max-width: ${Tablet}) {
		.container-mobile {
			padding-left: 15px;
			padding-right: 15px;
			margin-left: 0;
			max-width: 100%;
		}
	}

	.topIndex {
		z-index: 9;
		position: relative;
	}

	.titleBanner {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #4f4f4f;
		@media screen and (max-width: ${Mobile}) {
			font-size: 1.625rem;
			float: left;
			display: unset;
		}
	}

	.bannerSubTitle {
		padding-left: 25%;
		padding-right: 25%;
		color: #4f4f4f;
		@media only screen and (min-width: ${ExtraLarge}) {
			font-size: 1.5rem !important;
		}
		@media screen and (max-width: ${Tablet}) {
			padding-left: 10%;
			padding-right: 10%;
		}

		@media only screen and (min-width: ${Large}) {
			padding-left: 15%;
			padding-right: 15%;
		}
	}

	.textWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		padding-left: 10%;
		padding-right: 10%;
		@media only screen and (max-width: 1300px) {
			padding-left: 0%;
			padding-right: 0%;
		}

		.pDefault {
			margin-top: 1rem;
		}
	}

	.quarterCircle {
		background-image: url(https://seiturju.sirv.com/Chatbiz/v4/quarter-circle4.svg);
		width: 238px;
		height: 353px;
		transform: scale(1);
		position: absolute;
		top: 82px;
		right: 0;
		background-repeat: no-repeat;
		z-index: 1;

		transform-origin: right top;
		@media only screen and (max-width: ${Tablet}) {
			transform: scale(0.6);
			top: 60px;
			opacity: 0.25;
		}
	}
`;

export default Wrapper;
