import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Helmet } from 'react-helmet';
import Favicon from '../assets/img/favicon.ico';

import WhitePaper from '../componentsAtomic/templates/V4/WhitePaper';
import useDetectId from '../hooks/useDetectId';
import NotFound from './404';

const Render = () => {
	const intl = useIntl();
	const { isId } = useDetectId();
	if (isId) return <NotFound />;

	return (
		<>
			<Helmet title={intl.formatMessage({ id: `whitepaper.meta.title` })}>
				<meta name="description" content={intl.formatMessage({ id: `whitepaper.meta.desc` })} />
				<meta name="author" content="Chatbiz" />
				<meta name="theme-color" content="#04589b" />
				<link rel="icon" href={Favicon} />
				<html lang="id" />

				<link rel="stylesheet" href="https://sibforms.com/forms/end-form/build/sib-styles.css" />

				<script src="https://sibforms.com/forms/end-form/build/main.js" />
				<meta name="robots" content="index, follow" />
			</Helmet>
			<WhitePaper />
		</>
	);
};

export default Render;

// import React, { useState, useEffect } from 'react';
// import { Helmet } from 'react-helmet';
// import 'typeface-montserrat';
// import 'bootstrap/dist/css/bootstrap.css';
// import '../assets/_homepage/sass/main.scss';
// import 'lazysizes';

// import Navbar from '../components/_homepage/navbar';
// import SideBar from '../components/_homepage/sidebar';
// import WhitePaper from '../components/_homepage/whitePaper';
// import ModalRequest from '../components/_homepage/modalRequest';
// import Footer from '../components/_homepage/footer';
// import Favicon from '../assets/img/favicon.ico';

// const WhitePaperPage = () => {
// 	const [isSideBar, setSideBar] = useState(false);
// 	const [isModalDemo, setModalDemo] = useState(false);
// 	useEffect(() => {
// 		window.scrollTo(0, 0);
// 	}, []);
// 	return (
// 		<>
// 			<Helmet title="Whitepaper | Chatbiz.id">
// 				<meta
// 					name="description"
// 					content="Chatbiz Whitepaper adalah tempat untuk mengunduh berita, insight dan riset dari Chatbiz.id yang dapat memberikan manfaat bagi bisnis Anda."
// 				/>
// 				<meta name="author" content="Chatbiz" />
// 				<meta name="theme-color" content="#04589b" />
// 				<link rel="icon" href={Favicon} />
// 				<link rel="stylesheet" href="https://sibforms.com/forms/end-form/build/sib-styles.css" />

// 				<script src="https://sibforms.com/forms/end-form/build/main.js" />
// 			</Helmet>
// 			<div id="HomePageChatBiz" className="contentHomePage">
// 				<SideBar isSideBar={isSideBar} setSideBar={setSideBar} setModalDemo={setModalDemo} />
// 				<Navbar setSideBar={setSideBar} setModalDemo={setModalDemo} />
// 				<WhitePaper />
// 				<Footer />
// 				{isModalDemo && <ModalRequest setModalDemo={setModalDemo} />}
// 			</div>
// 		</>
// 	);
// };

// export default WhitePaperPage;
